import Image from 'next/image';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

import { Slider } from 'components/Slider';

const BankImageWrapper = styled(Box)`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  scroll-snap-align: start;
  min-width: 33.33%;
  margin: 2.5%;
`;

const BankImage = styled(Image)`
  width: 100%;
`;

interface SliderProps {
  bankLogoUrls: string[];
}
export default function BankSlider({ bankLogoUrls }: SliderProps) {
  return (
    <Slider
      arrowRight={<ArrowRight />}
      arrowLeft={<ArrowLeft />}
      arrowBackgroundRight="linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 30%)"
      arrowBackgroundLeft="linear-gradient(90deg, rgba(255,255,255,1) 70%, rgba(255,255,255,0) 100%);"
    >
      {bankLogoUrls.map((bankLogoUrl) => (
        <BankImageWrapper m={1.15} key={bankLogoUrl}>
          <BankImage
            src={bankLogoUrl}
            height={50}
            width={100}
            alt="Bank Logo"
            priority={false}
          />
        </BankImageWrapper>
      ))}
    </Slider>
  );
}

const IconWrapper = styled('div')(({ theme }) => ({
  background: theme.palette.background.default,
  boxShadow: '0px 0px 4px rgba(107, 106, 143, 0.15)',
  border: '0 solid transparent',
  borderRadius: `${theme.spacing(1)} !important`,
  cursor: 'pointer',
  outline: 'none',
  transition: 'all 0.25s',
  width: '24px',
  height: '24px',
}));

const ArrowRight: React.FC = () => (
  <IconWrapper>
    <ArrowRightIcon color="primary" />
  </IconWrapper>
);

const ArrowLeft: React.FC = () => (
  <IconWrapper>
    <ArrowLeftIcon color="primary" />
  </IconWrapper>
);
