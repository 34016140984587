import { APP_PAGE_URL } from 'pages/app-page';
import { ParsedUrlQuery } from 'querystring';

export const isOptimizelyAllowed = (
  pathname: string,
  query: ParsedUrlQuery,
): boolean => {
  if (pathname === APP_PAGE_URL || query.isAppContext === 'true') {
    return false;
  }

  return true;
};
