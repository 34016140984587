import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TrackButton } from 'components/TrackButton';
import { TrackingEvent } from 'lib/tracking/events';

const TrackButtonContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(-4),
  backgroundColor: theme.palette.grey[50],

  button: {
    width: '100%',
  },
}));

export const TrackButtonApp: React.FC = () => {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <TrackButtonContainer>
      <Container>
        <TrackButton
          variant="contained"
          onClick={handleClick}
          trackingEvent={TrackingEvent.ADVANTAGES_BUTTON_ONINTERACT}
        >
          Jetzt Kreditvergleich starten
        </TrackButton>
      </Container>
    </TrackButtonContainer>
  );
};