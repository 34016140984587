export const textImageObject = {
  name: 'Text & Image',
  headline: 'Wir erklären, warum wir Ihre persönlichen Angaben benötigen',
  text: {
    nodeType: 'document',
    data: {},
    content: [
      {
        nodeType: 'paragraph',
        content: [
          {
            nodeType: 'text',
            value:
              'Kreditinstitute sind dazu verpflichtet, Ihre Identität zu überprüfen. Nur mit den Angaben zu Ihrer Person kann das ',
            marks: [],
            data: {},
          },
          {
            nodeType: 'text',
            value: 'bestmögliche Angebot',
            marks: [
              {
                type: 'bold',
              },
            ],
            data: {},
          },
          {
            nodeType: 'text',
            value: ' für Sie ermittelt werden.',
            marks: [],
            data: {},
          },
        ],
        data: {},
      },
      {
        nodeType: 'paragraph',
        content: [
          {
            nodeType: 'text',
            value:
              'Vertrauenswürdige Angebote gibt es nur nach einer Kreditwürdigkeitsprüfung. Dafür benötigen wir Informationen zu Ihrer Person und finanziellen Situation.',
            marks: [],
            data: {},
          },
        ],
        data: {},
      },
      {
        nodeType: 'paragraph',
        content: [
          {
            nodeType: 'text',
            value: 'Ihre Angaben werden von smava ',
            marks: [],
            data: {},
          },
          {
            nodeType: 'text',
            value: 'vertraulich',
            marks: [
              {
                type: 'bold',
              },
            ],
            data: {},
          },
          {
            nodeType: 'text',
            value: ' behandelt und ',
            marks: [],
            data: {},
          },
          {
            nodeType: 'text',
            value: 'verschlüsselt',
            marks: [
              {
                type: 'bold',
              },
            ],
            data: {},
          },
          {
            nodeType: 'text',
            value:
              ' übermittelt. Der Schutz Ihrer persönlichen Daten hat für uns höchste Bedeutung.',
            marks: [],
            data: {},
          },
        ],
        data: {},
      },
    ],
  },
  image: {
    metadata: {
      tags: [],
    },
    sys: {
      space: {
        sys: {
          type: 'Link',
          linkType: 'Space',
          id: 'vh9g3jzzo9qx',
        },
      },
      id: '5PDch9Lyz7szvaOPcK86DE',
      type: 'Asset',
      createdAt: '2021-11-16T08:53:49.748Z',
      updatedAt: '2021-11-24T16:09:45.809Z',
      environment: {
        sys: {
          id: 'master',
          type: 'Link',
          linkType: 'Environment',
        },
      },
      revision: 12,
      locale: 'de-DE',
    },
    fields: {
      title: 'couple-on-couch.webp',
      file: {
        url: 'https://images.ctfassets.net/czunsr9k5tc8/4T6qSpiMYMiEf9hGGMXhu2/73f9b3bbf269ca28303242342cdfd722/couple-on-couch.webp?w=408',
        details: {
          size: 1073454,
          image: {
            width: 965,
            height: 964,
          },
        },
        fileName: '01_Watermelon_Logo_1.png',
        contentType: 'image/png',
      },
    },
  },
  altTextForImage: 'Paar auf einer Couch',
};
