import { Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const LegalTextContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(11),
  paddingBottom: theme.spacing(4),
  backgroundColor: theme.palette.grey[50],
}));

const LegalTextTypography = styled(Typography)(({ theme }) => ({
  display: 'block',
  marginBottom: theme.spacing(1),
}));

export const LegalTextApp: React.FC = () => {
  return (
    <LegalTextContainer>
      <Container>
        <LegalTextTypography variant="caption">
          **Mehr Info{' '}
          <a href="https://www.smava.de/kredit/info/">
            <strong>finden Sie hier</strong>
          </a>
          <br />
        </LegalTextTypography>
      </Container>
      <Container>
        <LegalTextTypography variant="caption">
          ***NTV: Telebörse (25.10.2023); ZDF: Morgenmagazin (02.02.2023); ARD:
          Morgenmagazin (02.02.2023); RTL: Guten Morgen Deutschland (06.12.2021)
        </LegalTextTypography>
      </Container>
    </LegalTextContainer>
  );
};