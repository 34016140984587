import Image from 'next/image';
import { styled } from '@mui/material/styles';
import { Box, Container, Typography } from '@mui/material';

import { RichText } from 'components/ContentfulContent/RichText/RichText';

const StepExplanationComponentWrapper = styled('div')`
  background-color: ${({ theme }) => theme.palette.background.default};
`;

const HeadlineWrapper = styled(Container)`
  margin-bottom: 0;

  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-bottom: 15px;
  }
`;

const AllStepsWrapper = styled(Container)`
  display: flex;

  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

const StepWrapper = styled('div')`
  display: flex;
  padding: ${(props) => props.theme.spacing(2)} 0;
  align-items: flex-start;
  width: 100%;
  :nth-of-type(2) {
    margin: 0;
  }

  ${(props) => props.theme.breakpoints.up('sm')} {
    flex-direction: column;
    width: 33%;
    padding: ${(props) => props.theme.spacing(5)} 0;
    :nth-of-type(2) {
      margin: 0 16px;
    }
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    flex-direction: row;
  }
`;

const TextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 16px;

  ${(props) => props.theme.breakpoints.up('sm')} {
    max-width: 240px;
    margin-left: 0;
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    margin-left: 16px;
  }
`;

export type StepExplanationsProps = any;

export default function StepExplanations({
  headline,
  steps,
}: StepExplanationsProps) {
  return (
    <StepExplanationComponentWrapper>
      <Box py={{ xs: 6, sm: 6, md: 12, lg: 12, xl: 12 }}>
        {headline && (
          <HeadlineWrapper>
            <Typography variant="h2">{headline}</Typography>
          </HeadlineWrapper>
        )}
        {steps && (
          <AllStepsWrapper maxWidth="lg">
            {steps.map((step: any) => {
              const { image } = step.fields as any;
              const imageFile = image?.fields.file;
              const plainImgUrl = imageFile?.url as string;
              const imgUrl = plainImgUrl?.startsWith('//')
                ? `https:${plainImgUrl}`
                : plainImgUrl;
              const altText =
                image?.fields.description || imageFile?.fileName || '';
              const { text } = step.fields as any;
              return (
                <StepWrapper key={step.sys.id}>
                  {imgUrl && (
                    <Image
                      src={imgUrl}
                      width={72}
                      height={72}
                      alt={altText as string}
                      priority={false}
                    />
                  )}
                  <TextWrapper>
                    <RichText document={text} />
                  </TextWrapper>
                </StepWrapper>
              );
            })}
          </AllStepsWrapper>
        )}
      </Box>
    </StepExplanationComponentWrapper>
  );
}
