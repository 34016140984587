import Image from 'next/image';
import { Typography, Tooltip } from '@mui/material';

import { styled } from '@mui/material/styles';

import TuvLogo from 'public/assets/certificates/tuev.svg';

const Headline = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
`;

const Text = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  margin: `${theme.spacing(2)} 0`,
}));

const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  margin: `auto ${theme.spacing(1)}`,
}));

export default function TuvCertificate() {
  return (
    <Wrapper>
      <Tooltip
        role="tooltip"
        arrow
        placement="left"
        title={
          <>
            <Headline variant="h6">
              100% Datenschutz - Die Sicherheit Ihrer Daten hat höchste
              Priorität
            </Headline>

            <Text>
              Seit 2015 unterziehen wir uns daher jährlich einer freiwilligen
              Datenschutzprüfung durch den TÜV Saarland.
            </Text>
            <Text>Das TÜV-Siegel wird erteilt von:</Text>
            <Text>
              TÜV Saarland i. A. Tobias Kohl, LL.M. Rechtsanwalt Consultant
              Datenschutz und Compliance
              <br />
              <a href="http://www.tekit.de/">www.tekit.de</a>
              <br />
              tekit Consult Bonn GmbH TÜV Saarland Gruppe Alexanderstraße 10,
              53111 Bonn
            </Text>
            <Text>
              <a href="https://www.smava.de/kredit/tuev-zertifikat/">
                Zum smava TÜV-Zertifikat
              </a>
            </Text>
          </>
        }
      >
        <div>
          <Image
            src={TuvLogo}
            alt="TÜV Zertifikat"
            width={100}
            height={53}
            priority
          />
        </div>
      </Tooltip>
    </Wrapper>
  );
}
